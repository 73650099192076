import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Badge,
  Card,
  Tabs,
  Tab,
} from "react-bootstrap";

import ImageOne from "../../images/retreat_4.jpg";
import ImageTwo from "../../images/retreat_0.jpg";
import ImageThree from "../../images/retreat_14.jpg";
import ImageFour from "../../images/retreat_5.jpg";
import ImageFive from "../../images/retreat_1.jpg";
import ImageSix from "../../images/retreat_11.jpg";
import ImageSeven from "../../images/retreat_6.jpg";
import ImageEight from "../../images/retreat_7.jpg";
import ImageNine from "../../images/retreat_8.jpg";
import ImageTen from "../../images/retreat_10.jpg";

import Calendar from "react-calendar";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import fetch from "node-fetch";
import { Link } from "gatsby";
import ReactImageGallery from "react-image-gallery";

const images = [
  {
    original: ImageOne,
    originalAlt: "The entrance.",
    originalWidth: 600,
    originalHeight: 800,
  },
  {
    original: ImageTwo,
    originalAlt: "View from the garden room.",
    originalWidth: 600,
    originalHeight: 800,
  },
  {
    original: ImageThree,
    originalAlt: "Inside the garden room.",
    originalWidth: 600,
    originalHeight: 800,
  },
  {
    original: ImageFour,
    originalAlt: "Outside seating area.",
    originalWidth: 600,
    originalHeight: 800,
  },
  {
    original: ImageFive,
    originalAlt: "King sized bed in the bedroom.",
    originalWidth: 800,
    originalHeight: 600,
  },
  {
    original: ImageSix,
    originalAlt: "Ensuite bathroom",
    originalWidth: 800,
    originalHeight: 600,
  },
  {
    original: ImageSeven,
    originalAlt: "Lounge area with TV & ornamental stove.",
    originalWidth: 800,
    originalHeight: 600,
  },
  {
    original: ImageEight,
    originalAlt: "Lounge area sofas",
    originalWidth: 600,
    originalHeight: 800,
  },
  {
    original: ImageNine,
    originalAlt: "Large kitchen area.",
    originalWidth: 800,
    originalHeight: 600,
  },
  {
    original: ImageTen,
    originalAlt: "Kitchen area sign.",
    originalWidth: 600,
    originalHeight: 800,
  },
];

export default class RhyllaRetreat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      dates: [],
      maxDate: undefined,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentDidMount() {
    // https://github.com/crashspringfield/react-google-calendar/blob/master/src/utils/googleAPI.js%20https://www.googleapis.com/calendar/v3/calendars/xyz@group.calendar.google.com/events?timeMin={date.format(%22yyyy-MM-dd%27T%2700:00:00Z%22)}&timeMax={date.addMonths(6).format(%22yyyy-MM-dd%27T%2723:59:59Z%22)}&singleEvents=true

    let date = new Date();
    let today = date.toISOString();

    let sixMonths = new Date(date.getFullYear(), date.getMonth() + 10, 0);
    let endDate = sixMonths.toISOString();
    let dates = [];
    // PRODUCTION
    return fetch(
      `https://content.googleapis.com/calendar/v3/calendars/shn42o6phu2oh51i950mbofktc@group.calendar.google.com/events?timeMax=${endDate}&timeMin=${today}&key=AIzaSyDm-4GgwIB-mfhDcnC_J6wTjxJFvVmBWLU`
    )
      .then((res) => res.json())
      .then((res) => {
        const items = res.items;

        for (const _date of items) {
          let start;
          let end;
          let tmp;

          if (_date.start.date) {
            start = new Date(_date.start.date);
          } else {
            tmp = new Date(_date.start.dateTime);
            start = new Date(tmp.getFullYear(), tmp.getMonth(), tmp.getDate());
          }

          if (_date.end.date) {
            end = new Date(_date.end.date);
          } else {
            tmp = new Date(_date.end.dateTime);
            end = new Date(tmp.getFullYear(), tmp.getMonth(), tmp.getDate());
          }

          dates.push({ start, end });
        }
        this.setState({
          dates: dates,
          maxDate: sixMonths,
          loaded: true,
        });
      });
  }

  render() {
    let calendar = <p>Loading calendar...</p>;
    if (this.state.loaded) {
      calendar = (
        <Calendar
          style={{ width: "100%" }}
          nextLabel={<FaChevronRight></FaChevronRight>}
          prevLabel={<FaChevronLeft></FaChevronLeft>}
          next2Label={null}
          prev2Label={null}
          minDate={new Date()}
          maxDate={this.state.maxDate}
          showNeighboringMonth={false}
          tileClassName={({ date, view }) => {
            if (view === "month") {
              let day = date.getDate();
              let month = date.getMonth();
              let year = date.getFullYear();

              var today = new Date();
              if (date <= today) {
                return "red";
              }

              for (const range of this.state.dates) {
                let _startDate = range.start;
                let _startDay = _startDate.getDate();
                let _startMonth = _startDate.getMonth();
                let _startYear = _startDate.getFullYear();

                let _endDate = range.end;
                let _endDay = _endDate.getDate();
                let _endMonth = _endDate.getMonth();
                let _endYear = _endDate.getFullYear();

                if (
                  day == _startDay &&
                  month == _startMonth &&
                  year == _startYear
                ) {
                  let foundYesterday = this.state.dates.findIndex(
                    (_yesterdayRange) => {
                      if (
                        date.getDate() == _yesterdayRange.end.getDate() &&
                        date.getMonth() == _yesterdayRange.end.getMonth() &&
                        date.getFullYear() == _yesterdayRange.end.getFullYear()
                      ) {
                        // if(dayBefore >= _yesterdayRange.start && dayBefore <= _yesterdayRange.end){
                        return true;
                      }
                    }
                  );

                  if (foundYesterday == -1) {
                    // console.log('date is NOT end date');
                    // Day is not an end date
                    // console.log('start day')
                    return "start";
                  } else {
                    // Day is also an end date
                    // console.log('also end date / handover')
                    return "red";
                  }
                } else if (
                  day == _endDay &&
                  month == _endMonth &&
                  year == _endYear
                ) {
                  let foundTomorrow = this.state.dates.findIndex(
                    (_tomorrowRange) => {
                      if (
                        date.getDate() == _tomorrowRange.start.getDate() &&
                        date.getMonth() == _tomorrowRange.start.getMonth() &&
                        date.getFullYear() == _tomorrowRange.start.getFullYear()
                      ) {
                        return true;
                      }
                    }
                  );

                  if (foundTomorrow == -1) {
                    return "end";
                  } else {
                    return "red";
                  }
                } else if (date > _startDate && date < _endDate) {
                  return "red";
                }
              }
            }
          }}
          formatShortWeekday={(locale, date) =>
            date.toLocaleString(locale, { weekday: "narrow" })
          }
        />
      );
    }

    return (
      <Layout>
        <SEO title="Retreat" />
        <div className="jumbotron">
          <Container>
            <Row style={{ marginBottom: 24 }}>
              <Col>
                <Row>
                  <Col md={true} sm={8}>
                    <h1>Rhylla Retreat</h1>
                  </Col>
                  <Col md={true} sm={8} className="badges">
                    <h2>
                      <Badge className="info-badge">From £80 / night</Badge>
                      &nbsp;
                      <Badge className="info-badge">2 Guests</Badge>
                    </h2>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
              <Col>
                <Row>
                  <Col>
                    <h3>About Rhylla Retreat</h3>
                    <p>
                      Rhylla Retreat has recently been renovated and modernised
                      to a high standard, whilst retaining many of the original
                      features.
                    </p>
                    <p>
                      Rhylla Retreat comprises of a front porch where you can
                      hang your coats & remove your well trodden walking boots &
                      wellies; a large newly installed farmhouse style kitchen &
                      dining area; a lounge with ornamental stove, original
                      wooden window shutters and twin leather sofas & T.V; a
                      bedroom with original wooden beams, king-size bed with
                      quality bedding and ample hanging/storage space; a newly
                      fitted modern en-suite shower room.
                    </p>
                    <p>
                      In addition, Rhylla Retreat benefits from a bright garden
                      room with 2 comfy easy chairs, ideal for simply reading or
                      taking in the view. There is an outdoor garden space with
                      table & chairs accessed through the garden room patio
                      doors.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginBottom: 48 }}>
              <Col>
                <ReactImageGallery
                  lazyLoad={true}
                  showThumbnails={false}
                  items={images}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 48 }}>
              <Col>
                <Tabs
                  variant="pills"
                  defaultActiveKey="amenities"
                  style={{
                    paddingLeft: "1rem",
                    fontWeight: 500,
                  }}
                >
                  <Tab eventKey="amenities" title="Amenities">
                    <Row>
                      <Col>
                        <ListGroup variant="flush">
                          <ListGroup.Item>Modern kitchen</ListGroup.Item>
                          <ListGroup.Item>Dishwasher</ListGroup.Item>
                          <ListGroup.Item>Modern shower room</ListGroup.Item>
                          <ListGroup.Item>TV</ListGroup.Item>
                          <ListGroup.Item>Garden room</ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col>
                        <ListGroup variant="flush">
                          <ListGroup.Item>WiFi</ListGroup.Item>
                          <ListGroup.Item>King sized bed</ListGroup.Item>
                          <ListGroup.Item>Private parking</ListGroup.Item>
                          <ListGroup.Item>Private garden</ListGroup.Item>
                          <ListGroup.Item>
                            Bed linen and shower room towels provided
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="location" title="Location">
                    <Row>
                      <Col md={4}>
                        <address
                          style={{
                            padding: "8px 16px 16px 16px",
                          }}
                        >
                          Rhylla
                          <br />
                          Townlake
                          <br />
                          Tavistock
                          <br />
                          Devon
                          <br />
                          PL19 8PQ
                        </address>
                      </Col>
                      <Col md={8}>
                        <iframe
                          width="100%"
                          height="300"
                          frameBorder="0"
                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d896.2876886686203!2d-4.2545015584180135!3d50.551200232673!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486c8b3e96e1f555%3A0x774c5326e85069fb!2sRhylla%20Cottage%20%26%20Rhylla%20Retreat!5e0!3m2!1sen!2sus!4v1648585720357!5m2!1sen!2sus"
                          allowFullScreen
                        ></iframe>
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
            <Card>
              <Card.Header as="h5">Availability</Card.Header>
              <Card.Body>
                <Row>
                  <Col md={8} style={{ textAlign: "center" }}>
                    {calendar}
                    <i>*3 night minimum stay</i>
                  </Col>
                  <Col md={4}>
                    <h5
                      style={{
                        marginTop: "16px",
                        marginBottom: "16px",
                      }}
                    >
                      Interested in booking or have an enquiry?
                    </h5>
                    <p>
                      Please contact us for up to date prices and late
                      availability special offers.
                    </p>
                    <Link to="/contact-us" className="btn btn-primary btn-lg">
                      Contact Us
                    </Link>
                    <br />
                    <br />
                    <Link to="https://www.airbnb.co.uk/rooms/591845455211152976">
                      Or view our Airbnb listing.
                    </Link>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </Layout>
    );
  }
}
